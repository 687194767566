export const GET_USERS = 'GET_USERS';
export const HEADCOUNTE = 'HEADCOUNTE';
export const FOOTERHOME = 'FOOTERHOME';
export const MAINSIDEBAR = 'MAINSIDEBAR';
export const EHREXCEPTIONEVENT = 'EHREXCEPTIONEVENT';
export const DEPARTMENTDETAIL = 'DEPARTMENTDETAIL';
export const PREVIOUSSHIFTDATA = 'PREVIOUSSHIFTDATA'; //setPreviousShiftData
export const GETPREVIOUSANDCURRENTSHIFT = 'GETPREVIOUSANDCURRENTSHIFT'; 
export const USERLOGIN = 'USERLOGIN';
export const ACTIVEDATEFILTER = 'ACTIVEDATEFILTER';
export const SELECTEDSHIFT = 'SELECTEDSHIFT';
export const PREVIOUSSHIFTDATAMODAL = 'PREVIOUSSHIFTDATAMODAL';
export const GRIDSHIFTDATAMODAL = 'GRIDSHIFTDATAMODAL'; 
export const GETPREVIOUSSHIFTDATA = 'GETPREVIOUSSHIFTDATA'; 
export const UPDATE_DATA = "UPDATE_DATA";
export const USER_DATA = "USER_DATA";
export const UPDATED_DATE = "UPDATED_DATE";
export const UPDATED_INDEX = "UPDATED_INDEX";
export const STACKED_DATE = "STACKED_DATE";
export const EXCEPTION_AGE = "EXCEPTION_AGE";
export const EXCEPTION_INDEX = "EXCEPTION_INDEX";
export const BUISNESS_HEADER = "BUISNESS_HEADER";
export const COSTCENTERDATA = "COSTCENTERDATA";
export const REALTIMEPAYPERIOD = "REALTIMEPAYPERIOD";
export const FILTERDATELIST = "FILTERDATELIST";
export const PERIODTYPE = "PERIODTYPE";
export const COSTCENTERSAPI = "COSTCENTERSAPI";



